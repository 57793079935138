import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Checkout from './components/Checkout';
import Success from './components/Success';
import Canceled from './components/Canceled';

import './css/normalize.css';
import './css/global.css';

// Utility function to fetch the token
const getToken = async () => {
  try {
    const response = await fetch("https://auth.reloadly.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: "PDwT9jOPHv30V0Y4DzXXKuwZCKQBrIUe",
        client_secret: "U4heY6okMq-lAQe1HI39ZFVytnjosx-WWBEOAfW88ex8taEf0s41qZdDe65UCwR",
        grant_type: "client_credentials",
        audience: "https://topups.reloadly.com",
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching token:", error);
    return null;
  }
};

function App() {
  const [tokenResponse, setTokenResponse] = useState(null);

  useEffect(() => {
    // Fetch the token when the component mounts
    getToken()
      .then((data) => setTokenResponse(data))
      .catch((error) => {
        // Handle token retrieval errors if needed
        console.error("Error retrieving token:", error);
      });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Pass the tokenResponse as a prop to the Success component */}
        <Route path="/success" element={tokenResponse ? <Success tokenResponse={tokenResponse} /> : null} />
        <Route path="/canceled" element={<Canceled />} />
        <Route path="/" element={tokenResponse ? <Checkout tokenResponse={tokenResponse} /> : null} />
   
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

