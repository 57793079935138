import React, { useState, useEffect } from "react";

// Custom hook for fetching data
function useDataFetcher(url, token) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token?.access_token}`,
          },
        });
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [url, token]);


  return { data, loading, error };
}

function Checkout({ tokenResponse }) {
  const { data: countriesResponse, loading: countriesLoading, error: countriesError } = useDataFetcher(
    "https://topups.reloadly.com/countries",
    tokenResponse
  );

  const [phone, setPhone] = useState("");
  const [iso, setIso] = useState("");
  const [operator, setOperator] = useState(null);
  const [amount, setAmount] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [amountError, setAmountError] = useState(""); // State for amount validation error
  const [apiErrorMessage, setApiErrorMessage] = useState(""); // State for API error message
  const [selectedCountry, setSelectedCountry] = useState("");
  


  const handleAmountChange = (e) => {
    const enteredAmount = parseFloat(e.target.value);
    setAmount(e.target.value);

    // Check if the entered amount is within the valid range
    if (
      (enteredAmount < operator.minAmount || enteredAmount > operator.maxAmount) &&
      !operator.fixedAmounts.includes(enteredAmount)
    ) {
      setAmountError("Amount must be within the valid range.");
    } else {
      setAmountError(""); // Clear the error message
    }
  };

  const fetchOperator = async () => {
    try {
      setIsSearching(true); // Show loading indicator
      const response = await fetch(
        `https://topups.reloadly.com/operators/auto-detect/phone/${phone}/country-code/${iso}?&includeBundles=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenResponse?.access_token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setOperator(data);
        setIsSearching(false); // Hide loading indicator
        setApiErrorMessage(""); // Clear any previous API error message
      } else {
        const errorResponse = await response.json();
        setOperator(null);
        setIsSearching(false); // Hide loading indicator
        setApiErrorMessage(errorResponse.message || "An error occurred."); // Display API error message
      }
    } catch (error) {
      console.error("Error fetching operator:", error);
      setIsSearching(false); // Hide loading indicator
      setApiErrorMessage("An error occurred."); // Display a generic error message
    }
  };

    // Handle the country selection change
  const handleCountryChange = (e) => {
      const selectedIso = e.target.value;
      setIso(selectedIso);
      setSelectedCountry(selectedIso); // Update the selected country
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetchOperator();
  };

  return (
    <div>
      <div className="text-title">
        <h1 className="text-title">Ergo Payments</h1>
      </div>
      <div className="container pt-5 mt-5" style={{ backgroundColor: "#E0F2F1" }}>
        <div className="text-center">
          <h1 style={{ fontSize: "2em", color: "#1A237E" }}>Top Up Now</h1>
        </div>
        <div className="row">
          <div className="col-6">
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label htmlFor="country" className="w-100 fields-text">
                  Country
                </label>
                <select
                  className="form-control"
                  id="country"
                  // onChange={(e) => setIso(e.target.value)   
                  // }
                  onChange={handleCountryChange}
                  
                >
                  {countriesResponse.map((country) => (
                    <option key={country.isoName} value={country.isoName}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group row">
                <label htmlFor="phone_number" className="w-100 fields-text">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control col"
                  id="phone_number"
                  placeholder="Enter phone number"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                />
                <button type="submit" className="ml-2 btn btn-primary col-auto" disabled={isSearching}>
                  {isSearching ? <i className="fa fa-spinner fa-spin"></i> : "Search"}
                </button>
              </div>
            </form>
          </div>

          <div
            className="col-6 d-none align-items-center text-center justify-content-center"
            id="details_box"
          >
            {operator && (
              <div className="row justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <img
                    src={operator.logoUrls[0]}
                    alt={operator.name}
                    width="100px"
                  />
                </div>
                <p>
                  <span className="operator_detail">Operator Name: </span>
                  <span id="operator_name">{operator.name}</span>
                  <br />
                  <span className="operator_detail">Currency Code: </span>
                  <span id="currency_code">{operator.senderCurrencyCode}</span>
                  <br />
                  <span className="operator_detail">Country Name: </span>
                  <span id="country_name">{operator.country.name}</span>
                </p>
              </div>
            )}
            {apiErrorMessage && <div className="text-danger">{apiErrorMessage}</div>}
          </div>
          {selectedCountry && (
          <div className="col-12">
            {operator && (
              <form>
                <div className="form-group row">
                  <label htmlFor="amount" className="amount-label">
                    Amount
                  </label>
                  {operator && (
                    <label className="amount-label">
                      {operator.fixedAmounts.length > 0 ? (
                        <span>
                          Available Fixed Amounts: {operator.fixedAmounts.join(", ")}
                        </span>
                      ) : (
                        <span>
                          Min Amount: {operator.minAmount} {operator.senderCurrencySymbol} | Max Amount: {operator.maxAmount} {operator.senderCurrencySymbol}
                        </span>
                      )}
                    </label>
                  )}
                  <input
                    type="number"
                    step="0.01"
                    className="form-control col"
                    id="amount"
                    name="amount"
                    placeholder="Enter amount"
                    required
                    value={amount}
                    onChange={handleAmountChange}
                  />
                  {amountError && (
                    <div className="text-danger">{amountError}</div> // Display error message
                  )}
                </div>
              </form>
            )}
            <div>
              {operator && (
                <form action="/create-checkout-session" method="POST">
                  <input type="hidden" name="amount" value={amount} />
                  <input type="hidden" name="phone" value={phone} />
                  <input type="hidden" name="iso" value={iso} />
                  <input
                    type="hidden"
                    name="operatorId"
                    value={operator ? operator.operatorId : null}
                  />
                  <button id="submit" role="link">
                    Buy
                  </button>
                </form>
              )}
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Checkout;


