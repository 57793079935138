
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';


const Success = ({ tokenResponse }) => {
  console.log("tokenResponse:", tokenResponse);

  const [session, setSession] = useState({});
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');
  const phone = new URLSearchParams(location.search).get('phone');
  const iso = new URLSearchParams(location.search).get('iso');
  const amount = parseFloat(new URLSearchParams(location.search).get('amount'));
  const operatorId = new URLSearchParams(location.search).get('operatorId');

  useEffect(() => {
    async function fetchSession() {
      try {
        const response = await fetch(`/checkout-session?sessionId=${sessionId}`);
        if (response.ok) {
          const data = await response.json();
          setSession(data);

          if (data.payment_status === 'paid' && operatorId) {
            // Construct the payload for sending the top-up request using the extracted parameters
            const payload = {
              recipientPhone: {
                countryCode: iso,
                number: phone,
              },
              operatorId: operatorId,
              amount: amount,
            };
            // Send the top-up request
            const topUpResponse = await fetch(
              "https://topups.reloadly.com/topups",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${tokenResponse?.access_token}`,
                },
                body: JSON.stringify(payload),
              }
            );

            if (topUpResponse.ok) {
              // Handle success (e.g., show a success message)
              console.log("Top-up sent successfully");
            } else {
              // Handle errors (e.g., show an error message)
              console.error("Error sending top-up:", topUpResponse.statusText);
            }
          }
        } else {
          console.error('Error fetching session data');
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    }

    // Fetch the session data when the component mounts
    fetchSession();
  }, []); // Empty dependency array ensures it runs only on mount

  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
        </header>
        <div className="sr-payment-summary completed-view">
          <h1>Status</h1>
          <h4>View CheckoutSession response:</h4>
          {/* <pre>{JSON.stringify(session, null, 2)}</pre> */}
          {session.payment_status === 'paid' ? (
            <p>Payment status: Paid- Your payment succeeded and Top Up sent successfully</p>
          ) : (
            <p>Payment status: Not paid -  Top Up Not sent successfully</p>
          )}
        </div>
        <div className="sr-section completed-view">
          <Link to="/">Home Page</Link>
        </div>
      </div>
      
  
    </div>
  );
};

export default Success;
